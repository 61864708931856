import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "reactstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import HomeContactComponent from "../components/HomeContactComponent"
import { sanitySerializers, sanityClientConfig } from "../utils/sanityConfig"
import SanityBlockContent from "@sanity/block-content-to-react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { MediaQueries } from "../utils/responsive"
import GoogleMaps from "../components/GoogleMaps"

const StyledContainer = styled(Container)`
  .content {
    margin: ${props => `${props.theme.margins.verticalMobile}px auto`};
    @media ${MediaQueries.mobile} {
      margin-top: ${props => `${props.theme.margins.verticalDesktop / 4}px`};
      margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
    }
    h2 {
      font-size: 2.4rem;
      width: 100%;
      line-height: 1.25;
      color: ${props => props.theme.colors.pink};
      text-transform: uppercase;
      @media ${MediaQueries.tablet} {
        font-size: 3.6rem;
      }
    }
    .block-content-h1 {
      h1 {
        line-height: 1.47;
        color: ${props => props.theme.colors.purple};
        font-size: 3.6rem;
        .highlight {
          color: ${props => props.theme.colors.pink};
        }
        a {
          font-family: Anton;
          font-weight: 400;
          font-size: 3.6rem;
          color: ${props => props.theme.colors.pink};
          &:hover {
            color: ${props => props.theme.colors.purple};
          }
        }
      }
    }
  }
`

const StyledButtonHolder = styled.div`
  padding: 0 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  max-width: 300px;
  margin-top: 2rem;
  @media ${MediaQueries.tablet} {
    max-width: 100%;
    flex: 1 0 100%;
  }
  @media ${MediaQueries.desktop} {
    max-width: 50%;
    flex: 0 0 50%;
  }
  button,
  a {
    text-align: center;
    width: 300px;

    border-radius: 40px;
    font-family: "Anton";
    font-size: 2.8rem;
    padding: 2rem;
    color: ${props => props.theme.colors.white};
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid transparent;
      text-decoration: none;
      color: white;
    }
  }
  button {
    background: ${props => props.theme.colors.purple};
    &:hover {
      background: ${props => props.theme.colors.pink};
    }
  }
  a {
    background: ${props => props.theme.colors.purple};
    &:hover {
      background: ${props => props.theme.colors.pink};
    }
  }
`

const LeadoknakPage = props => {
  const { t, i18n } = useTranslation("common")
  const language = i18n.language
  const mainPageData = props.data.sanityHomePage
  const thisPageData = props.data.sanityLeadoknakPage
  const shopsData = props.data.sanityShopPage

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  const seoImage = getImage(thisPageData?.image)?.images.fallback.src

  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <StyledContainer>
        <Seo
          title={`Cseriti - ${thisPageData?._rawTitle[language]}`}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={thisPageData?.image}
          slogan={thisPageData?._rawTitle[language]}
          // singleImage={true}
          video={true}
          videoLink={
            language === "hu"
              ? "https://www.youtube.com/embed/TJlSozXSt_g"
              : "https://www.youtube.com/embed/TJlSozXSt_g"
          }
        />
        {/* 1 Te Hozod */}
        <Row className="content">
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <div className="title-holder">
              <h2>{thisPageData?._rawTitleOne[language]}</h2>
            </div>
            <div className="description-holder">
              <SanityBlockContent
                key={(Math.random() * 1000 + 12).toString()}
                blocks={thisPageData?._rawDescriptionOne[language] || []}
                projectId={sanityClientConfig.sanity.projectId}
                dataset={sanityClientConfig.sanity.dataset}
                imageOptions={{ w: 200, h: 100, fit: "max" }}
                serializers={sanitySerializers}
              />
            </div>
          </Col>
          {/* Picture */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <GatsbyImage
              image={getImage(thisPageData?.image2?.asset)}
              alt={thisPageData?._rawTitleOne[language]}
            />
          </Col>

          {/* 2 Mi elszallitjuk */}
          <Col
            xs={12}
            lg={6}
            className="pt-5 pb-5"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <h2 className="text-center">
              {thisPageData?._rawTitleTwo[language]}
            </h2>
            <StyledButtonHolder style={{ alignItems: "flex-end" }}>
              <AnchorLink to="#leado">
                {thisPageData?._rawLinkTwo[language]}
              </AnchorLink>
            </StyledButtonHolder>
          </Col>
          {/* 3 NAGYOBB MENNYISÉG ESETÉN KÉRD INGYENES, HÁZHOZ MENŐ BEGYŰJTŐ SZOLGÁLTATÁSUNKAT! */}
          <Col
            xs={12}
            lg={6}
            className="pt-5 pb-5"
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <h2 className="text-center">
              {thisPageData?._rawTitleThree[language]}
            </h2>
            <StyledButtonHolder>
              <Link to={`/${language}/hazhoz`}>
                {thisPageData?._rawLinkThree[language]}
              </Link>
            </StyledButtonHolder>
          </Col>
          {/* 4 HASZNOS INFORMACIOK
           */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <h2>{thisPageData?._rawTitleFour[language]}</h2>
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionFour[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* KIDOBÁS HELYETT MIKOR GONDOLKODJ LEADÁSBAN? Text */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <GatsbyImage
              image={getImage(thisPageData?.image3?.asset)}
              alt={thisPageData?._rawTitleOne[language]}
            />
          </Col>

          {/* 5 MILYEN TÁRGYAKAKAT ÉRDEMES FELAJÁNLANOD MÁSOKNAK? */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            {/* <h2>{thisPageData?._rawTitleFive[language]}</h2> */}
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionFive[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* 6 MILYEN TÁRGYAKAKAT NEM TUDUNK FOGADNI? */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            {/* <h2>{thisPageData?._rawTitleSix[language]}</h2> */}
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionSix[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* 7 MILYEN TÁRGYAKAKAT NEM TUDUNK FOGADNI? */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            {/* <h2>{thisPageData?._rawTitleSeven[language]}</h2> */}
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionSeven[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
        </Row>

        <Row className="content">
          {/* 8 HOL TALÁLSZ MEG MINKET? */}
          <Col xs={12} lg={6} className="pt-5 pb-5" id="leado">
            <h2>{thisPageData?._rawTitleEight[language]}</h2>
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionEight[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          <Col xs={12}>
            <Col xs={12} className="px-2 px-sm-5">
              <GoogleMaps shops={shopsData.shops} />
            </Col>
          </Col>
        </Row>
        {/* 9 KIKNEK SEGÍTÜNK AZ ÁLTALAD LEADOTT HOLMIKKAL? */}
        <Row className="content">
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <h2>{thisPageData?._rawTitleNine[language]}</h2>
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionNine[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* Picture */}
          <Col xs={12} lg={6} className="pt-5 pb-5 ">
            <p>
              {" "}
              <GatsbyImage
                image={getImage(thisPageData?.image2?.asset)}
                alt={thisPageData?._rawTitleNine[language]}
              />
            </p>
          </Col>
          {/* Picture */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <p>
              {" "}
              <GatsbyImage
                image={getImage(thisPageData?.image4?.asset)}
                alt={thisPageData?._rawTitleNine[language]}
              />
            </p>
          </Col>
          {/* 10 KIKNEK SEGÍTÜNK AZ ÁLTALAD LEADOTT HOLMIKKAL? - Text 2 */}
          <Col xs={12} lg={6} className="pt-5 pb-5 d-flex align-items-center">
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionTen[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* 11 KIKNEK SEGÍTÜNK AZ ÁLTALAD LEADOTT HOLMIKKAL? Text 3*/}
          <Col xs={12} lg={6} className="pt-5 pb-5 d-flex align-items-center">
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionEleven[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
            />
          </Col>
          {/* Picture */}
          <Col xs={12} lg={6} className="pt-5 pb-5">
            <p>
              {" "}
              <GatsbyImage image={getImage(thisPageData?.image5?.asset)} />
            </p>
          </Col>
          {/* 12 Ha kíváncsi vagy a további társadalmi, szociális.... */}
          <Col xs={12} className="pt-5 pb-5 d-flex align-items-center">
            <SanityBlockContent
              key={(Math.random() * 1000 + 12).toString()}
              blocks={thisPageData?._rawDescriptionTwelve[language] || []}
              projectId={sanityClientConfig.sanity.projectId}
              dataset={sanityClientConfig.sanity.dataset}
              imageOptions={{ w: 200, h: 100, fit: "max" }}
              serializers={sanitySerializers}
              className="block-content-h1"
            />
          </Col>
        </Row>
      </StyledContainer>
      <HomeContactComponent intro={mainPageData?._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query LeadoknakPageQuery {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityLeadoknakPage {
      _rawDescriptionEight
      _rawDescriptionEleven
      _rawDescriptionFive
      _rawDescriptionFour
      _rawDescriptionNine
      _rawDescriptionOne
      _rawDescriptionSeven
      _rawDescriptionSix
      _rawDescriptionTen
      _rawDescriptionTwelve
      _rawImage
      _rawLinkThree
      _rawLinkTwo
      _rawTitle
      _rawTitleEight

      _rawTitleFour
      _rawTitleNine
      _rawTitleOne

      _rawTitleThree
      _rawTitleTwo
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image2 {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image3 {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image4 {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      image5 {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
    sanityShopPage {
      shops {
        name
        address
        phone
        _rawOpening
        location
        route
        opening {
          hu {
            children {
              text
            }
          }
        }
      }
    }
  }
`
export default LeadoknakPage
